import imageDesign from "src/assets/images/home-design.png";
import imageQR from "src/assets/images/qr-code.png";
import imageScanMe from "src/assets/images/scan-me.png";
import imageBGLogo from "src/assets/images/motif.png";

import { ButtonDefaultComponent } from "src/components/button";
import { useNavigate } from "react-router-dom";

export function HomeDesignComponent({ lang, language }) {
  const navigate = useNavigate();
  return (
    <div className="w-full flex justify-center items-center relative">
      <img src={imageBGLogo} className="absolute w-full object-contain" />
      <div className="lg:w-[1500px] w-11/12 grid grid-cols-12 relative py-20">
        <div className="lg:col-span-7 col-span-12 flex justify-center relative">
          <img src={imageDesign} className="-bottom-28 w-full absolute" />
        </div>
        <div className="lg:col-span-5 col-span-12 lg:mt-0 mt-6 flex flex-col justify-center">
          <h2
            className="text-[48px] font-bold lg:w-3/4"
            data-aos="fade-left"
            data-aos-duration="400"
          >
            {language[lang].home.design.title}
          </h2>
          <p
            className="mt-2 text-[18px] lg:w-3/4"
            data-aos="fade-left"
            data-aos-duration="200"
          >
            {language[lang].home.design.description}
          </p>

          <div className="flex gap-4 lg:mt-20 mt-6">
            <img src={imageQR} className="w-[150px] h-[150px] " />
            <img src={imageScanMe} className="w-[150px] h-[150px]" />
          </div>

          <div className="mt-8">
            <ButtonDefaultComponent
              title={language[lang].home.design.button}
              onClick={() => {
                navigate("/contact/information");
                window.scrollTo(0, 0);
              }}
              dark={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
