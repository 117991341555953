import { useContext, useEffect, useRef, useState } from "react";
import { FiChevronDown, FiMenu, FiX } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import imageLogo from "src/assets/images/logo.png";
import { LanguageContext } from "src/context/LanguageContext";
import language from "src/language/index.json";

import flagEN from "src/assets/images/flag/en.png";

export function NavbarComponent() {
  const navigate = useNavigate();
  const { lang } = useContext(LanguageContext);
  let menu = language[lang].component.navbar.menu;
  const link = [{
    main: "/",
    sub: []
  }, {
    main: "/product",
    sub: ["/self-service-kiosk", "/vending-machine"]
  },
  {
    main: "/about",
    sub: []
  },
  {
    main: "/career",
    sub: []
  },
  {
    main: "/contact",
    sub: ["/information", "/on-boarding"]
  }];

  const [sidebar, setSidebar] = useState(false);
  const [flag, setFlag] = useState(false);

  const [menuActive, setMenuActive] = useState(0);

  const navbarRef = useRef(null);

  useEffect(() => {
    getMenuActive();

    const handleScroll = () => {
      const threshold = 20;
      const scrolled = window.scrollY > threshold;

      if (scrolled) {
        navbarRef.current.classList.add("h-16");
        navbarRef.current.classList.remove("bg-opacity-80");
        navbarRef.current.classList.remove("h-12");
        navbarRef.current.classList.remove("top-5");
      } else {
        navbarRef.current.classList.add("bg-opacity-80");
        navbarRef.current.classList.add("h-12");
        navbarRef.current.classList.add("top-5");
        navbarRef.current.classList.remove("h-16");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  function getMenuActive() {
    const path = window.location.pathname;
    if (path === "/") {
      setMenuActive(0);
    } else if (path === "/product" || path === "/product/self-service-kiosk" || path === "/product/vending-machine") {
      setMenuActive(1);
    } else if (path === "/about") {
      setMenuActive(2);
    } else if (path === "/career") {
      setMenuActive(3);
    } else if (path === "/contact" || path === "/contact/information" || path === "/contact/on-boarding") {
      setMenuActive(4);
    }
  }

  return (
    <div
      className="w-full flex justify-center fixed z-40 bg-third h-12 top-5 transition-all duration-300 ease-in-out"
      ref={navbarRef}
    >
      {sidebar ? (
        <div className="left-0 top-0 right-0 bottom-0 fixed bg-third flex flex-col items-center z-10">
          <div className="flex items-center justify-between w-11/12 h-20">
            <button
              onClick={() => {
                navigate("/");
                window.scrollTo(0, 0);
              }}
            >
              <img src={imageLogo} alt="logo" className="h-14" />
            </button>

            <button
              onClick={() => {
                setSidebar(!sidebar);
              }}
            >
              <FiX size={20} />
            </button>
          </div>

          <div className="w-11/12">
            <ul className="flex flex-col text-white w-full text-center">
              {menu.map((item, index) => {
                return (
                  <li
                    key={index}
                    className="group"
                  >
                    <button
                       className={`py-3 w-full ${
                        menuActive == index ? "bg-primary text-dark" : ""
                      }`}
                      onClick={() => {
                        if(item.sub.length == 0){
                          navigate(link[index].main);
                          window.scrollTo(0, 0);
                        }
                      }}
                    >
                      {item.main}
                    </button>

                    <div className="group-hover:flex hidden flex-col">
                      { 
                        item.sub.map((sub, i) => {
                          return (
                            <button className="bg-third text-white border border-white hover:text-primary px-4 py-2"
                            
                            onClick={() => {
                              navigate(link[index].main + link[index].sub[i]);
                              window.scrollTo(0, 0);
                            }}>
                              {sub}
                            </button>
                          )
                        })
                      }
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : null}

      <div className="2xl:w-[1500px] w-11/12 flex justify-between items-center">
        <div className="lg:hidden block">
          <button
            onClick={() => {
              setSidebar(!sidebar);
            }}
          >
            <FiMenu size={20} />
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}
          >
            <img src={imageLogo} alt="logo" className="h-14" />
          </button>
        </div>

        <div className="grow flex justify-end gap-12">
          <div className="lg:block hidden">
            <ul className="flex space-x-8 text-white">
              {menu.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={`group relative overflow-visible flex flex-col`}
                  >
                    <button
                    className={`group-hover:text-primary ${
                      menuActive == index ? "text-primary" : ""
                    }`}
                      onClick={() => {
                        navigate(link[index].main);
                        window.scrollTo(0, 0);
                      }}
                    >
                      {item.main}
                    </button>

                    <div className="absolute  pt-10 group group-hover:flex hidden flex-col gap-1 w-fit">
                    {
                      item.sub.map((sub, i) => {
                        return (
                          <button className="bg-third text-white hover:text-primary text-left px-4 py-2"
                          
                          onClick={() => {
                            navigate(link[index].main + link[index].sub[i]);
                            window.scrollTo(0, 0);
                          }}>
                            {sub}
                          </button>
                        )
                      })
                    }
                    </div>
                  </li>
                );
              })}
              <li key={"login"} className="hover:text-primary">
                <a
                  onClick={() => {
                    window.location.href = "https://admin.dismore.tech/";
                  }}
                >
                  Log In
                </a>
              </li>
            </ul>
          </div>

          <div className="">
            <div className="relative">
              <button
                className="flex gap-1 items-center"
                onClick={() => {
                  setFlag(!flag);
                }}
              >
                <img src={flagEN} className="h-6" /> <FiChevronDown />
              </button>

              {flag ? (
                <ul className="absolute z-0 top-12 right-0 bg-third text-white px-2 w-48">
                  <li className="">
                    <button className="flex items-center gap-3 py-1 text-sm w-full">
                      <img src={flagEN} className="h-6" /> <span>English</span>
                    </button>
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
