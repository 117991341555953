import imageBG from "src/assets/images/about-bg-1.png";
import imageWoman from "src/assets/images/about-woman.png";
import imageLogo from "src/assets/images/logo-white-abstract.png";

export function AboutMainComponent({ lang, language }) {
  return (
    <div className="w-full flex justify-center lg:h-screen relative">
      <div className="lg:h-screen top-0 bottom-0 left-0 right-0 w-full absolute overflow-hidden">
        <img
          src={imageBG}
          alt="background"
          className="absolute w-full h-full object-cover"
        />
        <img
          src={imageWoman}
          alt="background"
          className="absolute lg:w-1/2 w-3/4 right-0 bottom-0"
        />
      </div>
      <div className="2xl:w-[1500px] w-11/12 relative z-20 text-center flex justify-center items-center py-20">
        <div className="flex flex-col items-center">
          <img src={imageLogo} />
          <h1
            className="font-bold lg:text-[56px] text-3xl lg:w-[700px] w-11/12 lg:leading-[56px] uppercase mt-8"
            data-aos="fade-up"
            data-aos-duration="400"
          >
            {language[lang].about.main.title}
          </h1>
          <p
            className="w-full mt-6 font-medium"
            data-aos="fade-up"
            data-aos-duration="200"
          >
            {language[lang].about.main.description}
          </p>
        </div>
      </div>
    </div>
  );
}
