import imageCustomer from "src/assets/icons/home-benefit-customer.png";
import imageOperational from "src/assets/icons/home-benefit-operational.png";
import imageService from "src/assets/icons/home-benefit-service.png";
import imageExpand from "src/assets/icons/home-benefit-expand.png";
import imageAttraction from "src/assets/icons/home-benefit-attraction.png";
import imageAdvertising from "src/assets/icons/home-benefit-advertising.png";
import imageModular from "src/assets/icons/home-benefit-modular.png";
import imagePerformance from "src/assets/icons/home-benefit-performance.png";

import imageBG2 from "src/assets/images/home-bg-2.png";

export function HomeBenefitComponent({ lang, language }) {
  const items = language[lang].home.benefit.items;
  const images = [
    imageCustomer,
    imageOperational,
    imageService,
    imageExpand,
    imageAttraction,
    imageAdvertising,
    imageModular,
    imagePerformance,
  ];

  return (
    <div className="w-full flex justify-center relative overflow-hidden">
      <img src={imageBG2} className="absolute w-full h-full object-cover" />
      <div className="2xl:w-[1500px] lg:w-11/12 w-full py-20 relative">
        <div>
          <h2
            className="text-[48px] font-semibold lg:w-96"
            data-aos="fade-right"
            data-aos-duration="400"
          >
            {language[lang].home.benefit.title}
          </h2>
        </div>
        <div className="flex flex-wrap gap-10 mt-12 justify-between">
          {items.map((item, index) => {
            return (
              <div
                key={index}
                className={`w-full 2xl:w-[330px] lg:w-[270px] h-[330px] lg:h-[270px] flex flex-col justify-between border-4 p-5 rounded-xl ${
                  (index % 2 === 0 && index < 4) ||
                  (index % 2 === 1 && index > 4)
                    ? "border-primary"
                    : "border-third"
                }`}
                data-aos="fade-up"
                data-aos-duration={(index + 1) * 200}
              >
                <img
                  src={images[index]}
                  alt="benefit"
                  className="2xl:w-[120px] lg:w-[75px] 2xl:h-[120px] object-cover ml-auto"
                />
                <h4 className="text-[18px] mt-8">{item}</h4>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
