import imageProduct from "src/assets/images/contact-product.png";
import imageWoman from "src/assets/images/coming-woman.png";
import { SendEmailServices } from "src/services/send-email";
import { useState } from "react";
import toast from "react-hot-toast";

export function ComingSoonFormComponent({ lang, language }) {
  const sendEmailServices = new SendEmailServices();
  const [data, setData] = useState({ email: "", text: "" });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await sendEmailServices.comingSoon({
      email: data.email,
      text: data.text,
    });

    if (res?.status === 200) {
      toast.success("Success send email");
    }
  };

  return (
    <div className="w-full flex justify-center relative">
      <div className="bg-dark h-full w-full absolute"></div>

      <div className="2xl:w-[1500px] w-11/12 grid grid-cols-12 pt-20 pb-48 relative">
        <div className="lg:col-span-6 col-span-12 relative flex justify-center items-center">
          <img
            src={imageWoman}
            className="2xl:w-3/4 2xl:-bottom-56 lg:w-[300px] lg:absolute w-1/2 relative"
            // data-aos="fade-right"
            // data-aos-duration="400"
          />
        </div>

        <div className="lg:col-span-6 col-span-12 flex flex-col justify-center text-white lg:mt-0 mt-8 ">
          <h1
            className="font-bold 2xl:text-[48px] lg:text-[36px] text-[28px]"
            data-aos="fade-left"
            data-aos-duration="400"
          >
            {language[lang]["coming-soon"].form.title}
          </h1>
          <p className="mt-4 2xl:text-[20px] ">
            {language[lang]["coming-soon"].form.description}
          </p>
          <div className="w-48 h-0.5 bg-white my-10"></div>
          <h6>{language[lang]["coming-soon"].form.tagline}</h6>

          <form onSubmit={handleSubmit}>
            <div className="flex flex-col mt-4 text-dark">
              <input
                type="email"
                name="email"
                onChange={handleChange}
                placeholder={
                  language[lang]["coming-soon"].form["email-placeholder"]
                }
                className="border border-gray-300 rounded-md p-2 outline-none lg:w-96 w-full"
              />
              <textarea
                className="border border-gray-300 rounded-md p-2 outline-none lg:w-96 w-full mt-2"
                placeholder={
                  language[lang]["coming-soon"].form["message-placeholder"]
                }
                rows={3}
                name="text"
                onChange={handleChange}
              ></textarea>
              <button
                type="submit"
                className="bg-primary text-dark font-semibold text-sm rounded-md p-2 px-5 mt-4 w-fit"
              >
                {language[lang]["coming-soon"].form.button}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
