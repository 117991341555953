import imageSolution1 from "src/assets/images/home-simply.png";
import imageSolution2 from "src/assets/images/home-slim.png";
import imageSolution3 from "src/assets/images/home-mounted.png";
import imageSolution4 from "src/assets/images/home-standing.png";

import Slider from "react-slick";

export function HomeSolutionComponent({ lang, language }) {
  const images = [
    imageSolution1,
    imageSolution2,
    imageSolution3,
    imageSolution4,
    imageSolution1,
  ];

  const items = language[lang].home.solution.items;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
  };

  return (
    <div className="w-full flex justify-center">
      <div className="w-11/12">
        <div className="2xl:w-[1500px] lg:w-11/12 mx-auto">
          <h6
            className="text-primary uppercase"
            data-aos="fade-right"
            data-aos-duration="200"
          >
            {language[lang].home.solution.label}
          </h6>
          <h1
            className="font-bold text-[48px] uppercase mt-2"
            data-aos="fade-right"
            data-aos-duration="500"
          >
            {language[lang].home.solution.title}
          </h1>
        </div>

        <div className="mt-8">
          <Slider {...settings}>
            {items.map((item, index) => (
              <div
                key={index}
                className="2xl:w-[400px] w-[250px] 2xl:h-[550px] h-[400px] relative"
                data-aos="fade-up"
                data-aos-duration={(index + 1) * 200}
              >
                <img
                  src={images[index]}
                  alt="solution"
                  className="w-[400px] lg:h-[550px] object-cover"
                />

                <h4 className="absolute text-[24px] font-[500] z-10 left-8 bottom-6 lg:block hidden">
                  {item}
                </h4>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
