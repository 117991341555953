import { ButtonDefaultComponent } from "src/components/button";

import imageQR from "src/assets/images/qr-code.png";
import imageFollow from "src/assets/images/follow-our-sosmed.png";
import imageProductCustom from "src/assets/images/product-custom.png";

import imageBG2 from "src/assets/images/product-bg-2.png";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

export function ProductPlanComponent({ lang, language }) {
  const navigate = useNavigate();
  const items = language[lang].product.pricing.items;

  return (
    <div className="w-full flex justify-center relative">
      <div className="w-full h-full bg-primary absolute"></div>
      <img src={imageBG2} className="absolute w-full h-full object-cover" />

      <div className="2xl:w-[1500px] lg:w-9/12 w-11/12 relative pt-20 lg:pb-40 pb-56">
        <div className="grid grid-cols-12">
          <div className="lg:col-span-4 col-span-12">
            <h2
              className="font-bold text-2xl text-dark"
              data-aos="fade-right"
              data-aos-duration="400"
            >
              {language[lang].product.pricing.title}
            </h2>
          </div>
          <div className="lg:col-span-8 col-span-12 lg:mt-0 mt-4">
            <p className="text-justify text-dark">
              {language[lang].product.pricing.description}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-12 mt-8 lg:gap-8">
          {items.map((item, index) => {
            return (
              <div
                className="bg-white p-8 lg:col-span-4 col-span-12 lg:mb-0 mb-8 text-dark"
                data-aos="fade-up"
                data-aos-duration={(index + 1) * 200}
              >
                <h3 className="font-bold 2xl:text-[28px] text-lg">
                  {item.title}
                </h3>
                <p className="text-sm text-dark mt-6 2xl:text-[20px]">
                  {item.description}
                </p>
                <div className="w-full h-0.5 bg-gray-300 my-6"></div>

                <div className="relative flex flex-col items-center my-14">
                  <div className="flex items-start gap-2 relative w-full">
                    <h6 className="text-xs 2xl:text-[20px]">
                      {item["discount-currency"]}
                    </h6>
                    <h4 className="text-4xl 2xl:text-[60px] font-bold">
                      {item["discount-price"]}{" "}
                      <span className="text-sm 2xl:text-[20px] font-normal relative">
                        / {item.periode}
                        <div className="flex items-start gap-1 absolute right-0 -top-12 text-red-500">
                          <h6 className="text-[6px] 2xl:text-[20px]">
                            {item.currency}
                          </h6>
                          <h4 className="text-[20px] 2xl:text-[40px] font-bold line-through">
                            {item.price}
                          </h4>
                        </div>
                      </span>
                    </h4>
                  </div>
                  <p className="absolute text-[12px] 2xl:text-[16px] -bottom-5">
                    {item["add-on"]}
                  </p>
                </div>

                <div className="flex flex-col gap-2 mt-4">
                  {item.benefit.map((feature, index) => {
                    return (
                      <div key={index} className="grid grid-cols-11 gap-2">
                        <div className="col-span-1 flex justify-center">
                          <FiCheckCircle className="text-secondary" size={24} />
                        </div>
                        <div className="col-span-10">
                          <p className="text-[20px]">{feature}</p>
                        </div>
                      </div>
                    );
                  })}

                  {item["not-benefit"].map((feature, index) => {
                    return (
                      <div key={index} className="grid grid-cols-11 gap-2">
                        <div className="col-span-1 flex justify-center">
                          <FiXCircle className="text-primary" size={24} />
                        </div>
                        <div className="col-span-10">
                          <p className="text-[20px]">{feature}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="mt-4">
                  <button
                    className={`hover:bg-primary text-white font-semibold text-sm rounded-md p-2 px-5 w-full ${
                      index % 2 == 0 ? "bg-dark" : "bg-primary"
                    }`}
                    onClick={() => {
                      navigate("/contact/information");
                      window.scrollTo(0, 0);
                    }}
                  >
                    {item["button"]}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <div className="grid grid-cols-12 mt-8">
          <div className="lg:col-span-4 col-span-12 text-dark">
            <h3
              className="font-bold text-lg 2xl:text-[28px]"
              data-aos="fade-right"
              data-aos-duration="400"
            >
              {language[lang].product["pricing-help"].title}
            </h3>
            <div className="w-20 h-1 bg-dark my-4"></div>
            <p data-aos="fade-right 2xl:text-[20px]" data-aos-duration="200">
              {language[lang].product["pricing-help"].description}
            </p>

            <div className="mt-8 text-white">
              <ButtonDefaultComponent
                title={language[lang].product["pricing-help"].button}
                dark={true}
              />
            </div>

            <div className="flex items-center mt-8 gap-4">
              <img src={imageQR} className="h-20" />
              <img src={imageFollow} className="h-20" />
            </div>
          </div>
          <div className="lg:col-span-8 col-span-12 relative">
            <img src={imageProductCustom} className="absolute" />
          </div>
        </div>
      </div>
    </div>
  );
}
