import imageBG from "src/assets/images/motif.png";
import imageProductType1 from "src/assets/images/product-type-1.png";
import imageProductType2 from "src/assets/images/product-type-2.png";
import imageProductType3 from "src/assets/images/product-type-3.png";

export function ProductTypeComponent({ lang, language }) {
  const items = language[lang].product.type.items;
  const images = [imageProductType1, imageProductType2, imageProductType3];

  return (
    <div className="w-full flex justify-center">
      <div className="2xl:w-[1500px] lg:w-9/12 w-11/12 grid grid-cols-9 py-20 lg:gap-8">
        <div className="lg:col-span-3 col-span-12 bg-primary p-8 ">
          <h1
            className=" font-bold 2xl:text-[48px] lg:text-[36px] text-[28px]"
            data-aos="fade-right"
            data-aos-duration="400"
          >
            {language[lang].product.type.title}
          </h1>
          <div className="h-1 w-48 bg-dark my-8"></div>
          <p
            className="mt-4 text-sm text-dark"
            data-aos="fade-right"
            data-aos-duration="200"
          >
            {language[lang].product.type.description}
          </p>

          <h4 className="mt-8 text-lg font-semibold text-dark">
            {language[lang].product.type.variant.title}
          </h4>
          <div className="mt-4 flex gap-4 italic text-dark">
            {language[lang].product.type.variant.items.map((item, index) => (
              <h5>{item}</h5>
            ))}
          </div>
        </div>

        {items.map((item, index) => {
          return (
            <div
              className="lg:col-span-2 col-span-12 relative lg:mt-0 mt-8 flex flex-col items-center justify-end 2xl:py-36 lg:py-20"
              data-aos="fade-up"
              data-aos-duration={(index + 1) * 200}
            >
              <img src={images[index]} className="w-[200px]" />
              <h3 className="text-lg font-bold mt-12 w-full">{item.title}</h3>
              <p className="mt-4">{item.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
