import imageCafe from "src/assets/images/home-collaborate-cafe.jpg";
import imageRetail from "src/assets/images/home-collaborate-retail.jpg";
import imageEvent from "src/assets/images/home-collaborate-event.jpg";
import imageFoodcourt from "src/assets/images/home-collaborate-foodcourt.jpg";
import Slider from "react-slick";

export function HomePartnerComponent({ lang, language }) {
  const items = language[lang].home.partner.items;
  const images = [imageCafe, imageRetail, imageEvent, imageFoodcourt];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    cssEase: "linear",
    arrows: false,
  };

  return (
    <div className="w-full flex justify-center">
      <div className="w-11/12 py-20">
        <div className="flex flex-col items-start">
          <h6
            className="text-primary uppercase text-[18px]"
            data-aos="fade-up"
            data-aos-duration="200"
          >
            {language[lang].home.partner.label}
          </h6>
          <h1
            className="font-bold lg:w-[700px] uppercase mt-2 text-[48px]"
            data-aos="fade-up"
            data-aos-duration="400"
          >
            {language[lang].home.partner.title}
          </h1>
        </div>

        <div className="mt-12 home-partner overflow-hidden h-[450px]">
          <Slider {...settings}>
            {items.map((item, index) => (
              <div>
                <div
                  key={index}
                  className="p-4 bg-primary flex-1 flex flex-col items-center"
                  data-aos="fade-up"
                  data-aos-duration={(index + 1) * 200}
                >
                  <img
                    src={images[index]}
                    alt="solution"
                    className="w-full lg:h-[380px] object-cover"
                  />

                  <h4 className="mt-2 text-dark font-[500] text-[24px] text-left w-full">
                    {item}
                  </h4>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
