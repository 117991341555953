export function ButtonDefaultComponent({ title, onClick, dark = false }) {
  return (
    <button
      className={`rounded-lg py-1 px-10 font-bold text-[16px] ${
        dark ? "bg-[#3B3E45] text-hite" : "bg-white text-dark"
      }`}
      onClick={onClick}
    >
      {title}
    </button>
  );
}
