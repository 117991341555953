import { useRef, useState } from "react";
import imageBG from "../../../assets/images/home-bg-1.jpg";
import { ButtonDefaultComponent } from "src/components/button";

import imageScaleCost from "src/assets/icons/home-scale-cost.png";
import imageScaleFlexibility from "src/assets/icons/home-scale-flexible.png";
import imageScaleDifficult from "src/assets/icons/home-scale-difficult.png";
import imageScaleFraud from "src/assets/icons/home-scale-fraud.png";
import imageScaleMonitor from "src/assets/icons/home-scale-monitor.png";

import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

export function HomeMainComponent({ lang, language }) {
  const navigate = useNavigate();
  return (
    <div className="w-full flex justify-center lg:h-screen relative">
      <div className="lg:h-screen top-0 bottom-0 left-0 right-0 w-full absolute overflow-hidden">
        <img
          src={imageBG}
          alt="background"
          className="absolute w-full h-full object-cover"
        />
        <div className="left-0 right-0 top-0 bottom-0 bg-dark opacity-80 absolute"></div>
      </div>
      <div className="w-11/12 relative z-20 text-center flex justify-center items-center py-20">
        <div className="flex flex-col items-center absolute 2xl:top-80">
          <h1
            className="font-bold lg:text-[96px] text-3xl lg:w-2/3 w-11/12 lg:leading-[96px] uppercase"
            data-aos="fade-up"
            data-aos-duration="600"
          >
            {language[lang].home.main[0].title}
          </h1>
          <p
            className="w-2/3 mt-4 font-medium text-[16px]"
            data-aos="fade-up"
            data-aos-duration="400"
          >
            {language[lang].home.main[0].description}
          </p>
          <div className="mt-8">
            <ButtonDefaultComponent
              title={language[lang].home.main[0].button}
              onClick={() => {
                navigate("/contact/information");
                window.scrollTo(0, 0);
              }}
            />
          </div>
        </div>
      </div>

      <ScaleComponent lang={lang} language={language} />
    </div>
  );
}

function ScaleComponent({ lang, language }) {
  const items = language[lang].home.scale.items;
  const images = [
    imageScaleCost,
    imageScaleFlexibility,
    imageScaleDifficult,
    imageScaleFraud,
    imageScaleMonitor,
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "",
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
  };

  return (
    <div className="2xl:w-[1500px] lg:w-9/12 z-20 bg-black bg-opacity-85 shadow-xl p-8 rounded-3xl absolute -bottom-20 lg:grid hidden grid-cols-12 gap-4">
      <h2 className="font-bold 2xl:text-[48px] text-[28px] text-white col-span-4">
        {language[lang].home.scale.title}
      </h2>
      <div className="col-span-1 flex items-center"></div>
      <div className="col-span-7 2xl:ws-[800pxs] 2xl:h-[200px] h-[140px] overflow-hidden slider-container home-main-scale">
        <Slider {...settings}>
          {items.map((item, index) => (
            <div className="2xl:w-[200px] 2xl:h-[200px] h-[140px] bg-primary p-4 rounded-2xl">
              <div
                key={index}
                className="flex flex-col items-center justify-between h-full"
              >
                <img
                  src={images[index]}
                  alt="icon"
                  className="2xl:w-1/2 w-1/3"
                />
                <p className="text-dark mt-2 text-[16px] text-center line-clamp-2">
                  {item}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

function ScaleComponentsss({ lang, language }) {
  const scaleRef = useRef(null);
  const [current, setCurrent] = useState(0);

  const items = language[lang].home.scale.items;
  const images = [
    imageScaleCost,
    imageScaleFlexibility,
    imageScaleDifficult,
    imageScaleFraud,
    imageScaleMonitor,
  ];

  function handleScroll(direction) {
    if (direction === "left") {
      if (current === 0) {
        setCurrent(items.length - 1);
        scaleRef.current.scrollLeft = 300 * (items.length - 1);
      } else {
        setCurrent(current - 1);
        scaleRef.current.scrollLeft = 300 * (current - 1);
      }
    } else {
      if (current === items.length - 1) {
        setCurrent(0);
        scaleRef.current.scrollLeft = 0;
      } else {
        setCurrent(current + 1);
        scaleRef.current.scrollLeft = 300 * (current + 1);
      }
    }
  }

  return (
    <div className="w-[1500px] z-20 bg-black bg-opacity-85 shadow-xl p-8 rounded-3xl absolute -bottom-20 lg:grid hidden grid-cols-12 gap-4">
      <h2 className="font-bold text-[48px] text-white col-span-4">
        {language[lang].home.scale.title}
      </h2>

      {current == 0 ? (
        <div className="col-span-1 flex items-center">
          <button
            onClick={() => {
              handleScroll("left");
            }}
            className="cursor-pointer"
          >
            <FiChevronLeft size={56} className="text-primary" />
          </button>
        </div>
      ) : null}

      <div
        className="col-span-7 overflow-hidden flex transition duration-500 ease-in-out"
        ref={scaleRef}
      >
        <div className="flex gap-4 flex-nowrap w-[1200px] ">
          {items.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-between w-[200px] h-[200px] bg-primary p-4 rounded-2xl"
            >
              <img src={images[index]} alt="icon" className="w-1/2" />
              <p className="text-dark mt-2 text-xs text-center h-8 line-clamp-2">
                {item}
              </p>
            </div>
          ))}
        </div>
      </div>

      {current != 0 ? (
        <div className="col-span-1 flex items-center">
          <button
            onClick={() => {
              handleScroll("right");
            }}
            className="cursor-pointer"
          >
            <FiChevronRight size={56} className="text-primary" />
          </button>
        </div>
      ) : null}
    </div>
  );
}
