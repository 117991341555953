import imageBG from "src/assets/images/motif.png";
import imageLogo from "src/assets/images/logo-white-abstract.png";

export function ComingSoonMainComponent({ lang, language }) {
  return (
    <div className="w-full flex justify-center relative items-center">
      <div className="bg-dark h-full w-full absolute"></div>
      <img src={imageBG} alt="background" className="absolute w-full" />
      <div className="2xl:w-[1500px] w-11/12 relative z-20 text-center flex justify-center items-center text-white lg:py-48 py-20">
        <div className="flex flex-col items-center">
          <img src={imageLogo} />
          <h1
            className="font-bold lg:text-[56px] 2xl:text-[48px] text-3xl lg:w-[700px] w-11/12 lg:leading-[56px] uppercase"
            data-aos="fade-up"
            data-aos-duration="400"
          >
            {language[lang]["coming-soon"].main.title}
          </h1>
          <p
            className="w-full mt-4 font-medium 2xl:text-[20px]"
            data-aos="fade-up"
            data-aos-duration="400"
          >
            {language[lang]["coming-soon"].main.description}{" "}
            <span className="text-primary">
              {language[lang]["coming-soon"].main["description-span"]}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
