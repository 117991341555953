import { NavbarComponent } from "src/components/navbar";
import language from "src/language/index.json";
import { useContext } from "react";
import { LanguageContext } from "src/context/LanguageContext";
import { FooterComponent } from "src/components/footer";
import { CallToActionDefaultComponent } from "src/components/call-to-action";
import { ProductMainComponent } from "./component/main";
import { ProductTypeComponent } from "./component/type";
import { ProductDeviceComponent } from "./component/device";
import { ProductExpandComponent } from "./component/expand";
import { ProductPlanComponent } from "./component/plan";
import { ProductMotifComponent } from "./component/motif";

export default function ProductVendingMachinePage() {
  const { lang } = useContext(LanguageContext);

  return (
    <div className="overflow-hidden">
      <NavbarComponent />
      <ProductMainComponent lang={lang} language={language} />
      <ProductMotifComponent />
      <ProductTypeComponent lang={lang} language={language} />
      <ProductDeviceComponent lang={lang} language={language} />
      <ProductExpandComponent lang={lang} language={language} />
      <ProductPlanComponent lang={lang} language={language} />
      <CallToActionDefaultComponent lang={lang} language={language} />
      <FooterComponent lang={lang} language={language} />
    </div>
  );
}
