import imageBG from "src/assets/images/about-bg-1.png";
import imageWoman from "src/assets/images/about-woman.png";
import imageLogo from "src/assets/images/logo-white-abstract.png";

export function ContactMainComponent({ lang, language }) {
  return (
    <div className="w-full flex justify-center lg:h-screen relative">
      <div className="lg:h-screen top-0 bottom-0 left-0 right-0 w-full absolute overflow-hidden">
        <img
          src={imageBG}
          alt="background"
          className="absolute w-full h-full object-cover"
        />
        <img
          src={imageWoman}
          alt="background"
          className="absolute lg:w-1/2 w-3/4 right-0 bottom-0"
        />
      </div>
      <div className="2xl:w-[1500px] w-11/12 relative z-20 text-center flex justify-center items-center py-20">
        <div className="flex flex-col items-center">
          <img src={imageLogo} />
          <h1
            className="font-bold lg:text-[56px] 2xl:text-[48px] text-3xl lg:w-[700px] w-11/12 lg:leading-[56px] uppercase mt-8"
            data-aos="fade-up"
            data-aos-duration="400"
          >
            {language[lang].about.main.title}
          </h1>
          <p
            className="w-full mt-4 font-medium text-[20px]"
            data-aos="fade-up"
            data-aos-duration="400"
          >
            {language[lang].about.main.description}
          </p>
        </div>
      </div>
    </div>
  );
}

// export function ContactMainComponent({ lang, language }) {
//   return (
//     <div className="flex justify-center">
//       <div className="block sm:hidden lg:hidden md:hidden xl:hidden ">
//         <iframe
//           src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d480.03483879543927!2d112.79565544594047!3d-7.2758610417458485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7fbf6e7f9b8eb%3A0x6ea58ec888de7821!2sScience%20Technopark%20ITS!5e0!3m2!1sid!2sid!4v1713857740435!5m2!1sid!2sid"
//           width="400"
//           height="400"
//           allowfullscreen=""
//           loading="lazy"
//           referrerpolicy="no-referrer-when-downgrade"
//         ></iframe>
//       </div>
//       <div className="hidden sm:block lg:hidden md:hidden xl:hidden ">
//         <iframe
//           src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d480.03483879543927!2d112.79565544594047!3d-7.2758610417458485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7fbf6e7f9b8eb%3A0x6ea58ec888de7821!2sScience%20Technopark%20ITS!5e0!3m2!1sid!2sid!4v1713857740435!5m2!1sid!2sid"
//           width="640"
//           height="640"
//           allowfullscreen=""
//           loading="lazy"
//           referrerpolicy="no-referrer-when-downgrade"
//         ></iframe>
//       </div>
//       <div className="hidden sm:hidden lg:block md:hidden xl:hidden ">
//         <iframe
//           src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d480.03483879543927!2d112.79565544594047!3d-7.2758610417458485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7fbf6e7f9b8eb%3A0x6ea58ec888de7821!2sScience%20Technopark%20ITS!5e0!3m2!1sid!2sid!4v1713857740435!5m2!1sid!2sid"
//           width="768"
//           height="640"
//           allowfullscreen=""
//           loading="lazy"
//           referrerpolicy="no-referrer-when-downgrade"
//         ></iframe>
//       </div>
//       <div className="hidden sm:hidden lg:hidden md:block xl:hidden ">
//         <iframe
//           src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d480.03483879543927!2d112.79565544594047!3d-7.2758610417458485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7fbf6e7f9b8eb%3A0x6ea58ec888de7821!2sScience%20Technopark%20ITS!5e0!3m2!1sid!2sid!4v1713857740435!5m2!1sid!2sid"
//           width="1024"
//           height="640"
//           allowfullscreen=""
//           loading="lazy"
//           referrerpolicy="no-referrer-when-downgrade"
//         ></iframe>
//       </div>
//       <div className="hidden sm:hidden lg:hidden md:hidden xl:block">
//         <iframe
//           src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d480.03483879543927!2d112.79565544594047!3d-7.2758610417458485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd7fbf6e7f9b8eb%3A0x6ea58ec888de7821!2sScience%20Technopark%20ITS!5e0!3m2!1sid!2sid!4v1713857740435!5m2!1sid!2sid"
//           width="1280"
//           height="640"
//           allowfullscreen=""
//           loading="lazy"
//           referrerpolicy="no-referrer-when-downgrade"
//         ></iframe>
//       </div>
//     </div>
//   );
// }
