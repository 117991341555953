import imageProductDevice from "src/assets/images/product-device.png";
import imageProductPaymentInternational from "src/assets/images/product-payment-international.png";
import imageProductPaymentNational from "src/assets/images/product-payment-national.png";
import imageProductDeviceBG from "src/assets/images/product-device-bg.jpg";
import iconProductCash from "src/assets/icons/product-device-cash.png";
import iconProductQR from "src/assets/icons/product-device-qr.png";
import iconProductCard from "src/assets/icons/product-device-card.png";
import { ButtonDefaultComponent } from "src/components/button";
import { useNavigate } from "react-router-dom";

export function ProductDeviceComponent({ lang, language }) {
  const navigate = useNavigate();

  const items = language[lang].product.payment.items;
  const icons = [iconProductCash, iconProductQR, iconProductCard];

  return (
    <div className="w-full flex flex-col items-center relative">
      <div className="bg-primary lg:w-8/12 w-full h-full absolute left-0"></div>
      <img
        className="absolute w-4/12 right-0 bottom-0 lg:block hidden"
        src={imageProductDeviceBG}
      />
      <div className="bg-dark lg:w-4/12 bg-opacity-70 h-full absolute right-0"></div>

      <div className="2xl:w-[1500px] lg:w-9/12 w-11/12 relative grid grid-cols-12 lg:gap-8 pt-20">
        <div className="lg:col-span-6 col-span-12">
          <h1
            className="text-dark font-bold 2xl:text-[48px] text-[28px]"
            data-aos="fade-right"
            data-aos-duration="400"
          >
            {language[lang].product.device.title}
          </h1>
          <div className="w-48 h-1 bg-dark my-8"></div>
          <p
            className="mt-4 text-sm text-dark"
            data-aos="fade-right"
            data-aos-duration="200"
          >
            {language[lang].product.device.description.map((item, index) => {
              if (index % 2 != 0) {
                return (
                  <span className="font-semibold">
                    {` `}
                    {item}
                  </span>
                );
              } else {
                return (
                  <span>
                    {` `}
                    {item}
                  </span>
                );
              }
            })}
          </p>
          <h1
            className="text-[#393E46] font-bold 2xl:text-[64px] lg:text-[36px] text-[28px] mt-6"
            data-aos="fade-up"
            data-aos-duration="400"
          >
            {language[lang].product.device.tagline}
          </h1>

          <div className="mt-8">
            <ButtonDefaultComponent
              title={language[lang].product.device.button}
              dark={true}
              onClick={() => {
                navigate("/contact/information");
                window.scrollTo(0, 0);
              }}
            />
          </div>
        </div>
        <div
          className="lg:col-span-6 col-span-12 lg:mt-0 mt-8"
          data-aos="fade-left"
          data-aos-duration="400"
        >
          <img src={imageProductDevice} />
        </div>
      </div>
      <div className="2xl:w-[1500px] lg:w-9/12 w-11/12 relative pb-20 grid grid-cols-12 mt-12 lg:gap-8">
        <div className="lg:col-span-3 col-span-12">
          <h1
            className="text-dark font-bold text-[28px]"
            data-aos="fade-right"
            data-aos-duration="400"
          >
            {language[lang].product.payment.title}
          </h1>
          <div className="w-48 h-1 bg-dark my-10"></div>
          <p
            className="mt-4 text-sm text-dark"
            data-aos="fade-right"
            data-aos-duration="200"
          >
            {language[lang].product.payment.description}
          </p>
        </div>
        <div className="lg:col-span-7 col-span-12 bg-white bg-opacity-80 lg:p-8 p-6 lg:mt-0 mt-8">
          <div className="grid grid-cols-12 lg:gap-8">
            {items.map((item, index) => {
              return (
                <div
                  className="lg:col-span-4 col-span-12 lg:mb-0 mb-8"
                  data-aos="fade-right"
                  data-aos-duration="400"
                >
                  <div className="flex items-end gap-4">
                    <h3 className="text-primary font-bold text-xl">
                      {item.title}
                    </h3>
                    <img src={icons[index]} className="h-10" />
                  </div>
                  <div className="w-20 h-0.5 bg-dark lg:mt-8 mt-6 mb-4"></div>

                  <p className="mt-4 text-sm text-dark">{item.description}</p>
                </div>
              );
            })}
          </div>

          <div className="mt-8 text-xl font-bold">
            <h4
              data-aos="fade-right"
              data-aos-duration="400"
              className="text-dark"
            >
              {language[lang].product.payment["payment-support"]}
            </h4>

            <div
              className="grid grid-cols-12 w-full lg:gap-8 mt-4"
              data-aos="fade-up"
              data-aos-duration="400"
            >
              <img
                src={imageProductPaymentNational}
                className="lg:col-span-5 col-span-12"
              />
              <img
                src={imageProductPaymentInternational}
                className="lg:col-span-5 col-span-12 lg:mt-0 mt-8"
              />
            </div>
          </div>
        </div>

        <div className="col-span-2"></div>
      </div>
    </div>
  );
}
