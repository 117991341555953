import { FiMapPin, FiPhone } from "react-icons/fi";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";
import imageLogo from "src/assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import imageWAGreen from "src/assets/icons/whatsapp-green.png";

export function FooterComponent({ lang, language }) {
  const navigate = useNavigate();
  const links = ["", "/about", "/career", "/contact"];

  const products = ["/product/self-service", "/product/vending-machine"];

  return (
    <div className="w-full flex flex-col items-center bg-dark bg-opacity-80">
      <div className="flex justify-center w-full py-12">
        <div className="2xl:w-[1300px] lg:w-9/12 w-11/12">
          <div className="grid grid-cols-12 gap-4">
            <div className="lg:col-span-3 col-span-12">
              <img src={imageLogo} alt="logo" />
              <p className="text-sm mt-2">
                {language[lang].component.footer.description}
              </p>

              <div className="flex gap-2 mt-4">
                <div className="h-8 w-8 flex justify-center items-center rounded-full bg-white bg-opacity-30">
                  <a target="_blank" href="https://facebook.com">
                    <FaFacebookF size={12} className="" />
                  </a>
                </div>
                <div className="h-8 w-8 flex justify-center items-center rounded-full bg-white bg-opacity-30">
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/dismore/"
                  >
                    <FaLinkedinIn size={12} className="" />
                  </a>
                </div>
                <div className="h-8 w-8 flex justify-center items-center rounded-full bg-white bg-opacity-30">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/dismore.tech/"
                  >
                    <FaInstagram size={12} className="" />
                  </a>
                </div>
                <div className="h-8 w-8 flex justify-center items-center rounded-full bg-white bg-opacity-30">
                  <a target="_blank" href="https://youtube.com">
                    <FaYoutube size={12} className="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="lg:col-span-2 col-span-6">
              <h4 className="font-bold text-lg">
                {language[lang].component.footer.link.title}
              </h4>

              <ul className="mt-4">
                {language[lang].component.footer.link.items.map(
                  (item, index) => (
                    <li key={index} className="py-1">
                      <button
                        onClick={() => {
                          navigate(links[index]);
                          window.scrollTo(0, 0);
                        }}
                      >
                        {item}
                      </button>
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="lg:col-span-2 col-span-6">
              <h4 className="font-bold text-lg ">
                {language[lang].component.footer.product.title}
              </h4>

              <ul className="mt-4">
                {language[lang].component.footer.product.items.map(
                  (item, index) => (
                    <li key={index} className="py-1">
                      <button
                        onClick={() => {
                          navigate(products[index]);
                          window.scrollTo(0, 0);
                        }}
                      >
                        {item}
                      </button>
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="lg:col-span-5 col-span-12">
              <h4 className="font-bold text-lg ">
                {language[lang].component.footer.info.title}
              </h4>

              <div className="flex gap-3 mt-4">
                <FiMapPin size={24} className="text-third" />
                <h5>{language[lang].component.footer.info.surabaya}</h5>
              </div>

              <div className="flex gap-3 mt-3">
                <FiMapPin size={24} className="text-third" />
                <h5>{language[lang].component.footer.info.germany}</h5>
              </div>

              <div className="flex gap-3 mt-3 relative">
                <FiPhone size={24} className="text-third" />
                <div className="relative">
                  <h5>{language[lang].component.footer.info.phone}</h5>
                  <button
                    onClick={() => {
                      window.open("https://wa.me/6281330058541");
                    }}
                    className="flex items-center gap-2 absolute -right-16"
                  >
                    <h5 className="text-xs italic underline">
                      {language[lang].component.footer.info.available}
                    </h5>
                    <img src={imageWAGreen} className="w-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <h5 className="">{language[lang].component.footer.pt}</h5>
          </div>
        </div>
      </div>
      <div className="h-[1px] bg-black w-full"></div>
      <div className="py-5">
        <h6>{language[lang].component.footer.copyright}</h6>
      </div>
    </div>
  );
}
