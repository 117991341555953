import axios from "axios";

const API_URL = "https://api-contact.dismore.tech";
// const API_URL = "http://localhost:5465";
const destinationEmail = "admin@dismore.tech";

export class SendEmailServices {
  async forwardEmail({
    to = destinationEmail,
    fullname,
    phone,
    business,
    email,
    product,
    text,
  }) {
    try {
      const res = await axios.post(API_URL + "/forward", {
        to,
        fullname,
        phone,
        business,
        email,
        product,
        text,
      });

      return res;
    } catch (error) {
      console.error(error);
    }
  }

  async trialCustomer({ to = destinationEmail, email }) {
    try {
      const res = await axios.post(API_URL + "/trial", {
        to,
        email,
      });

      return res;
    } catch (error) {
      console.error(error);
    }
  }

  async comingSoon({ to = destinationEmail, email, text }) {
    try {
      const res = await axios.post(API_URL + "/coming-soon", {
        to,
        email,
        text,
      });

      return res;
    } catch (error) {
      console.error(error);
    }
  }

  async onBoarding({ formData }) {
    formData.append("to", destinationEmail);
    try {
      const res = await axios.post(API_URL + "/on-boarding", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return res;
    } catch (error) {
      console.error(error);
    }
  }
}
