import { useState } from "react";
import toast from "react-hot-toast";
import imageProduct from "src/assets/images/contact-product.png";
import { SendEmailServices } from "src/services/send-email";

export function ContactProductComponent({ lang, language }) {
  const sendEmailServices = new SendEmailServices();
  const [data, setData] = useState({ email: "" });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await sendEmailServices.trialCustomer({
      email: data.email,
    });

    if (res?.status === 200) {
      toast.success("Success send email");
    }
  };

  return (
    <div className="w-full flex justify-center lg:min-h-screen relative">
      <div className="2xl:w-[1500px] lg:w-9/12 grid grid-cols-12 py-20 relative">
        <div className="lg:col-span-7 col-span-12 flex flex-col justify-center">
          <h1
            className="font-bold 2xl:text-[48px] lg:text-[36px] text-[28px]"
            data-aos="fade-right"
            data-aos-duration="400"
          >
            {language[lang].contact.product.title}
            <span className="text-secondary">
              {` `}
              {language[lang].contact.product["title-span"]}
            </span>
          </h1>
          <p
            className="mt-4 text-[20px]"
            data-aos="fade-right"
            data-aos-duration="200"
          >
            {language[lang].contact.product.description}
          </p>
          <div className="w-48 h-1 bg-white my-10"></div>
          <h6>
            <span className="font-bold">
              {language[lang].contact.product["label-span"]}
            </span>
            {` `}
            {language[lang].contact.product.label}
          </h6>

          <form onSubmit={handleSubmit}>
            <div className="flex gap-2 mt-4 text-dark">
              <input
                type="email"
                name="email"
                value={data.email}
                onChange={handleChange}
                placeholder={language[lang].contact.product.placeholder}
                className="border border-gray-300 rounded-md p-2 outline-none lg:w-96 w-1/2"
              />
              <button
                type="submit"
                className="bg-primary text-dark font-semibold text-sm rounded-md p-2 px-5"
              >
                {language[lang].contact.product.button}
              </button>
            </div>
          </form>
        </div>
        <div className="lg:col-span-5 col-span-12 relative lg:mt-0 mt-8 flex items-center">
          <img
            src={imageProduct}
            className="lg:w-[500px] 2xl:w-full lg:absolute relative"
          />
        </div>
      </div>
    </div>
  );
}
