import { useState } from "react";
import toast from "react-hot-toast";
import { SendEmailServices } from "src/services/send-email";
import imageWoman from "src/assets/images/contact-woman.png";
import imageDefault from "src/assets/images/default.png";
import imageApp from "src/assets/images/contact-app.png";

export function ContactFormComponent({ lang, language }) {
  const sendEmailServices = new SendEmailServices();

  const [data, setData] = useState({
    outletName: "",
    phone: "",
    outletAddress: "",
    imageId: "",
    imageVAT: "",
    bankName: "",
    bankAccountNumber: "",
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("outletName", data.outletName);
    formData.append("phone", data.phone);
    formData.append("outletAddress", data.outletAddress);
    formData.append("imageId", data.imageId);
    formData.append("imageVAT", data.imageVAT);
    formData.append("bankName", data.bankName);
    formData.append("bankAccountNumber", data.bankAccountNumber);

    const res = await sendEmailServices.onBoarding({ formData });

    if (res?.status === 200) {
      toast.success("Success send email");

      setData({
        outletName: "",
        phone: "",
        outletAddress: "",
        imageId: "",
        imageVAT: "",
        bankName: "",
        bankAccountNumber: "",
      });
    }
  };

  return (
    <div className="w-full flex justify-center relative">
      <div className=" h-full w-full absolute"></div>

      <div className="2xl:w-[1500px] w-10/12 grid grid-cols-12 pt-0 pb-48 relative">
        <div className="lg:col-span-6 col-span-12 relative flex flex-col justify-center items-center gap-10">
          <img
            src={imageWoman}
            className="2xl:w-3/4 lg:w-11/12 w-1/2 relative"
          />

          <img
            src={imageApp}
            className="2xl:w-3/4 lg:w-[300px] w-1/2 relative"
          />
        </div>

        <div className="lg:col-span-6 col-span-12 flex flex-col justify-center text-white lg:mt-0 mt-8 ">
          <h1
            className="font-bold 2xl:text-[48px] lg:text-[36px] text-[28px]"
            data-aos="fade-left"
            data-aos-duration="400"
          >
            {language[lang]["on-boarding"].form.title}
          </h1>
          <p className="mt-4 2xl:text-[20px] text-gray-300">
            {language[lang]["on-boarding"].form.description}
          </p>
          <div className="w-48 h-0.5 bg-white my-10"></div>
          <h6 className="font-bold">
            {language[lang]["on-boarding"].form.tagline}
          </h6>

          <form onSubmit={handleSubmit} className="mt-5">
            <div className="flex flex-col mt-4 text-dark gap-6">
              <div className="flex gap-4">
                <input type="checkbox" id="term1" required />
                <label htmlFor="term1" className="ml-2 text-white">
                  {language[lang]["on-boarding"].form["terms"][0]}
                </label>
              </div>

              <label
                htmlFor="imageId"
                className="w-full h-28 bg-white flex items-center justify-center rounded-md relative"
              >
                <h4 className="left-3 top-3 absolute text-sm font-bold">
                  {language[lang]["on-boarding"].form["id"]}
                </h4>
                {data.imageId != "" ? (
                  <h4 className="text-center w-full px-10 absolute">
                    {data.imageId.name}
                  </h4>
                ) : (
                  <img
                    src={imageDefault}
                    alt="default-image"
                    className="w-20 absolute"
                  />
                )}

                <input
                  type="file"
                  className="opacity-0"
                  id="imageId"
                  name="imageId"
                  onChange={handleFileChange}
                  required
                />
              </label>

              <input
                type="text"
                name="outletName"
                value={data.outletName}
                onChange={handleChange}
                placeholder={language[lang]["on-boarding"].form["outlet"]}
                className="border border-gray-300 rounded-md p-2 outline-none w-full"
                required
              />

              <input
                type="text"
                name="phone"
                value={data.phone}
                onChange={handleChange}
                placeholder={language[lang]["on-boarding"].form["phone"]}
                className="border border-gray-300 rounded-md p-2 outline-none w-full"
                required
              />

              <input
                type="text"
                name="outletAddress"
                value={data.outletAddress}
                onChange={handleChange}
                placeholder={
                  language[lang]["on-boarding"].form["outlet-address"]
                }
                className="border border-gray-300 rounded-md p-2 outline-none w-full"
                required
              />

              <label
                htmlFor="imageVAT"
                className="w-full h-28 bg-white flex items-center justify-center rounded-md relative"
              >
                <h4 className="left-3 top-3 absolute text-sm font-bold">
                  {language[lang]["on-boarding"].form["vat"]}
                </h4>
                {data.imageVAT != "" ? (
                  <h4 className="text-center w-full px-10 absolute">
                    {data.imageVAT.name}
                  </h4>
                ) : (
                  <img
                    src={imageDefault}
                    alt="default-image"
                    className="w-20 absolute"
                  />
                )}
                <input
                  type="file"
                  className="opacity-0"
                  id="imageVAT"
                  name="imageVAT"
                  onChange={handleFileChange}
                  required
                />
              </label>

              <div className="flex gap-4">
                <input type="checkbox" id="term2" required />
                <label htmlFor="term2" className="ml-2 text-white">
                  {language[lang]["on-boarding"].form["terms"][1]}
                </label>
              </div>

              <input
                type="text"
                name="bankName"
                value={data.bankName}
                onChange={handleChange}
                placeholder={language[lang]["on-boarding"].form["bank"]}
                className="border border-gray-300 rounded-md p-2 outline-none w-full"
                required
              />

              <input
                type="text"
                name="bankAccountNumber"
                value={data.bankAccountNumber}
                onChange={handleChange}
                placeholder={language[lang]["on-boarding"].form["bank-account"]}
                className="border border-gray-300 rounded-md p-2 outline-none w-full"
                required
              />

              <button
                type="submit"
                className="bg-primary text-dark font-semibold text-sm rounded-md p-2 px-5 mt-4 w-fit ml-auto"
              >
                {language[lang]["on-boarding"].form.button}
              </button>

              <p className="text-white text-sm text-right mt-10">
                {language[lang]["on-boarding"].form.information}
                <span className="text-primary"> admin@dismore.tech</span>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
