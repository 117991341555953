import imageFast from "src/assets/icons/home-why-fast.png";
import imageEasy from "src/assets/icons/home-why-easy.png";
import imageSelf from "src/assets/icons/home-why-self.png";
import imageMulti from "src/assets/icons/home-why-multi.png";
import imageConnected from "src/assets/icons/home-why-connected.png";
import imageRepeat from "src/assets/icons/home-why-repeat.png";
import imageQR from "src/assets/icons/home-why-qr.png";

import imageSupport from "src/assets/icons/home-why-support.png";
import imageForecasting from "src/assets/icons/home-why-forecasting.png";
import imageOperational from "src/assets/icons/home-why-operational.png";
import imageMany from "src/assets/icons/home-why-many.png";
import imageFlexible from "src/assets/icons/home-why-flexible.png";
import imageAdvertising from "src/assets/icons/home-why-advertising.png";
import imageSecure from "src/assets/icons/home-why-secure.png";

import Slider from "react-slick";

export function HomeWhyComponent({ lang, language }) {
  const items = language[lang].home.why.items;
  const images = [
    [
      imageFast,
      imageEasy,
      imageSelf,
      imageMulti,
      imageConnected,
      imageRepeat,
      imageQR,
    ],
    [
      imageSupport,
      imageForecasting,
      imageOperational,
      imageMany,
      imageFlexible,
      imageAdvertising,
      imageSecure,
    ],
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    cssEase: "linear",
    arrows: true,
  };

  return (
    <div className="w-full flex justify-center">
      <div className="w-11/12 py-20">
        <div className="2xl:w-[1500px] lg:w-11/12 mx-auto flex flex-col items-end">
          <h6
            className="text-primary uppercase"
            data-aos="fade-left"
            data-aos-duration="200"
          >
            {language[lang].home.why.label}
          </h6>
          <h1
            className="font-bold text-3xl lg:w-[500px] text-right uppercase mt-2"
            data-aos="fade-left"
            data-aos-duration="400"
          >
            {language[lang].home.why.title}
          </h1>
        </div>

        <div className="w-full mx-auto">
          <Slider {...settings}>
            {items.map((page, iPage) => {
              return (
                <div>
                  <div className="flex flex-wrap justify-center gap-12 mt-16 text-center">
                    {page.map((item, iItem) => {
                      return (
                        <div
                          className="lg:w-96 w-full mb-5"
                          data-aos="fade-up"
                          data-aos-duration={(iItem + 1) * 200}
                        >
                          <img
                            src={images[iPage][iItem]}
                            alt="why"
                            className="mx-auto w-20 h-20 object-cover"
                          />
                          <h4 className="font-bold text-[24px] mt-4">
                            {item.title}
                          </h4>
                          <p className="text-sm mt-2 text-[18px]">
                            {item.description}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}
