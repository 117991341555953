import { NavbarComponent } from "src/components/navbar";
import language from "src/language/index.json";
import { useContext } from "react";
import { LanguageContext } from "src/context/LanguageContext";
import { FooterComponent } from "src/components/footer";
import { CallToActionDefaultComponent } from "src/components/call-to-action";
import { ContactMainComponent } from "./component/main";
import { ContactFormComponent } from "./component/form";
import { ContactProductComponent } from "./component/product";
import { ContactMotifComponent } from "./component/motif";

export default function ContactInformationPage() {
  const { lang } = useContext(LanguageContext);

  return (
    <div className="overflow-hidden">
      <NavbarComponent />
      <ContactMainComponent lang={lang} language={language} />
      <ContactFormComponent lang={lang} language={language} />
      <ContactMotifComponent />
      <ContactProductComponent lang={lang} language={language} />
      <CallToActionDefaultComponent lang={lang} language={language} />
      <FooterComponent lang={lang} language={language} />
    </div>
  );
}
