import imageAboutProduct from "src/assets/images/about-product.png";

export function AboutWhoComponent({ lang, language }) {
  return (
    <div className="w-full flex justify-center">
      <div className="2xl:w-[1500px] lg:w-9/12 w-11/12 grid grid-cols-12 lg:gap-8 py-20">
        <div className="lg:col-span-6 col-span-12 flex flex-col justify-center">
          <h2
            className="font-bold text-[48px]"
            data-aos="fade-right"
            data-aos-duration="400"
          >
            {language[lang].about.who.title}
          </h2>
          <p className="text-justify mt-4 2xl:text-[24px]">
            {language[lang].about.who.description}
          </p>
        </div>
        <div className="lg:col-span-6 col-span-12 lg:mt-0 mt-8">
          <img
            src={imageAboutProduct}
            className="lg:w-10/12 w-full mx-auto"
            data-aos="fade-left"
            data-aos-duration="400"
          />
        </div>
      </div>
    </div>
  );
}
