import imageMap from "src/assets/images/about-map.png";
import { ButtonDefaultComponent } from "src/components/button";

export function AboutMapComponent({ lang, language }) {
  return (
    <div className="w-full flex justify-center">
      <div className="max-w-[1366px] w-11/12 py-20">
        <h2
          className="text-2xl font-bold"
          data-aos="fade-right"
          data-aos-duration="400"
        >
          {language[lang].about.map.title}
        </h2>

        <div className="mt-8 flex justify-center">
          <img src={imageMap} className="lg:w-9/12 w-full" />
        </div>

        <div className="mt-8 flex lg:flex-row flex-col justify-between items-center">
          <ButtonDefaultComponent
            title={language[lang].about.map.button}
            dark={true}
          />
          <p
            className="lg:mt-0 mt-4 italic"
            data-aos="fade-left"
            data-aos-duration="400"
          >
            {language[lang].about.map.note}
          </p>
        </div>
      </div>
    </div>
  );
}
