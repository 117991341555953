import { useState } from "react";
import toast from "react-hot-toast";
import { SendEmailServices } from "src/services/send-email";
import imageWAYellow from "src/assets/icons/whatsapp-yellow.png";

export function ContactFormComponent({ lang, language }) {
  const sendEmailServices = new SendEmailServices();

  const [data, setData] = useState({});

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await sendEmailServices.forwardEmail(data);

    if (res?.status === 200) {
      toast.success("Success send email");
    }
  };

  return (
    <div className="w-full flex justify-center relative">
      <div className="bg-dark lg:w-1/2 w-full h-full absolute left-0"></div>
      <div className="bg-primary lg:w-1/2 h-full absolute right-0"></div>
      <div className="2xl:w-[1500px] w-11/12 grid grid-cols-12 relative">
        <div className="lg:col-span-4 col-span-12 text-white bg-dark py-20">
          <div className="border-l-4 border-t-4 border-b-4 p-16 border-primary h-full">
            <h2 className="font-semibold text-2xl 2xl:leading-[64px] 2xl:text-[64px]">
              {language[lang].contact.location.title}
            </h2>

            <div className="mt-16">
              {language[lang].contact.location.items.map((item, index) => (
                <div
                  key={index}
                  className="mt-8"
                  data-aos="fade-right"
                  data-aos-duration={(index + 1) * 100}
                >
                  <h3 className="font-semibold text-lg">{item.city}</h3>
                  <p className="text-sm">{item.address}</p>
                </div>
              ))}
            </div>

            <div className="mt-16">
              <h3 className="font-semibold text-lg">
                {language[lang].contact.location.mail.label}
              </h3>
              <p
                className="text-sm text-primary"
                data-aos="fade-right"
                data-aos-duration="400"
              >
                {language[lang].contact.location.mail.value}
              </p>
            </div>

            <div className="mt-8 pr-4">
              <p className="italic">
                {language[lang].contact.location.message}
              </p>
              <h3
                className="font-semibold text-primary mt-2"
                data-aos="fade-right"
                data-aos-duration="400"
              >
                {language[lang].contact.location["contact-person"]}
              </h3>
              <div className="mt-4 flex items-center gap-4">
                <img src={imageWAYellow} className="w-10" />
                <a
                  href="https://wa.me/6281330058541"
                  target="_blank"
                  className=""
                >
                  {language[lang].contact.location["wa"]}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:col-span-8 bg-primary col-span-12 lg:mt-0 mt-8 text-dark py-20">
          <div className="flex flex-col items-center border-r-4 border-t-4 border-b-4 p-16 border-dark h-full">
            <h2 className="text-2xl font-bold text-center">
              {language[lang].contact.form.title}
            </h2>

            <form className="lg:w-9/12 w-full" onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <div className="lg:col-span-1 col-span-2">
                  <label>{language[lang].contact.form.name}</label>
                  <input
                    name="fullname"
                    onChange={handleChange}
                    className="border border-dark bg-transparent rounded-md p-2 outline-none w-full mt-1"
                  />
                </div>
                <div className="lg:col-span-1 col-span-2">
                  <label>{language[lang].contact.form.phone}</label>
                  <input
                    name="phone"
                    onChange={handleChange}
                    className="border border-dark bg-transparent rounded-md p-2 outline-none w-full mt-1"
                  />
                </div>
                <div className="lg:col-span-1 col-span-2">
                  <label>{language[lang].contact.form.business}</label>
                  <input
                    name="business"
                    onChange={handleChange}
                    className="border border-dark bg-transparent rounded-md p-2 outline-none w-full mt-1"
                  />
                </div>
                <div className="lg:col-span-1 col-span-2">
                  <label>{language[lang].contact.form.email}</label>
                  <input
                    name="email"
                    onChange={handleChange}
                    className="border border-dark bg-transparent rounded-md p-2 outline-none w-full mt-1"
                  />
                </div>
                <div className="col-span-2">
                  <label>{language[lang].contact.form["product-type"]}</label>
                  <input
                    name="product"
                    onChange={handleChange}
                    className="border border-dark bg-transparent rounded-md p-2 outline-none w-full mt-1"
                  />
                </div>
                <div className="col-span-2">
                  <label>{language[lang].contact.form.message}</label>
                  <textarea
                    placeholder={
                      language[lang].contact.form["message-placeholder"]
                    }
                    rows={3}
                    name="text"
                    onChange={handleChange}
                    className="border border-dark bg-transparent rounded-md p-2 outline-none w-full mt-1"
                  ></textarea>
                </div>
              </div>

              <div className="mt-4">
                {language[lang].contact.form.terms.map((term, index) => {
                  return (
                    <div className="flex gap-4 mb-4">
                      <input type="checkbox" className="ml-2" required />
                      <label className="text-sm">{term}</label>
                    </div>
                  );
                })}
              </div>

              <button
                type="submit"
                className="bg-dark text-white font-semibold text-sm rounded-md p-2 px-5 mt-4"
              >
                {language[lang].contact.form.button}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
