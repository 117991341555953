import { NavbarComponent } from "src/components/navbar";
import { HomeMainComponent } from "./component/main";
import language from "src/language/index.json";
import { useContext } from "react";
import { LanguageContext } from "src/context/LanguageContext";
import { HomeAboutComponent } from "./component/about";
import { HomeSolutionComponent } from "./component/solution";
import { HomeWhyComponent } from "./component/why";
import { HomeDesignComponent } from "./component/design";
import { HomeBenefitComponent } from "./component/benefit";
import { HomePartnerComponent } from "./component/partner";
import { FooterComponent } from "src/components/footer";
import { CallToActionDefaultComponent } from "src/components/call-to-action";

export default function HomePage() {
  const { lang } = useContext(LanguageContext);

  return (
    <div className="overflow-hidden">
      <NavbarComponent />
      <HomeMainComponent lang={lang} language={language} />
      <HomeAboutComponent lang={lang} language={language} />
      <HomeSolutionComponent lang={lang} language={language} />
      <HomeWhyComponent lang={lang} language={language} />
      <HomeDesignComponent lang={lang} language={language} />
      <HomeBenefitComponent lang={lang} language={language} />
      <HomePartnerComponent lang={lang} language={language} />
      <CallToActionDefaultComponent lang={lang} language={language} />
      <FooterComponent lang={lang} language={language} />
    </div>
  );
}
