import { NavbarComponent } from "src/components/navbar";
import language from "src/language/index.json";
import { useContext } from "react";
import { LanguageContext } from "src/context/LanguageContext";
import { FooterComponent } from "src/components/footer";
import { CallToActionDefaultComponent } from "src/components/call-to-action";
import { ComingSoonMainComponent } from "./component/main";
import { ComingSoonFormComponent } from "./component/form";

export default function ComingSoonPage() {
  const { lang } = useContext(LanguageContext);

  return (
    <div className="overflow-hidden">
      <NavbarComponent />
      <ComingSoonMainComponent lang={lang} language={language} />
      <ComingSoonFormComponent lang={lang} language={language} />
      <CallToActionDefaultComponent lang={lang} language={language} />
      <FooterComponent lang={lang} language={language} />
    </div>
  );
}
