import { Toaster } from "react-hot-toast";
import HomePage from "./pages/home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppContextProvider } from "./context/AppContextProvider";
import AboutPage from "./pages/about";
import ComingSoonPage from "./pages/coming-soon";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "react-multi-carousel/lib/styles.css";

import AOS from "aos";
import "aos/dist/aos.css";
import { useContext, useEffect } from "react";
import { LanguageContext } from "./context/LanguageContext";
import { getIP } from "./services/get-ip";
import { getIPbyCountry } from "./services/get-country-by-ip";
import ContactInformationPage from "./pages/contact/information";
import ContactOnBoardingPage from "./pages/contact/on-boarding";
import ProductSelfServiceKioskPage from "./pages/product/self-service-kiosk";
import ProductVendingMachinePage from "./pages/product/vending-machine";

AOS.init();

function App() {
  return (
    <AppContextProvider>
      <div className="App bg-dark text-white">
        <MainRouter />
        <Toaster />
      </div>
    </AppContextProvider>
  );
}

export default App;

function MainRouter() {
  const { lang, setLang } = useContext(LanguageContext);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    const ip = await getIP();
    const country = await getIPbyCountry(ip);
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/product/self-service-kiosk" element={<ProductSelfServiceKioskPage />} />
        <Route path="/product/self-service-kiosk" element={<ProductVendingMachinePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact/information" element={<ContactInformationPage />} />
        <Route path="/contact/on-boarding" element={<ContactOnBoardingPage />} />
        <Route path="*" element={<ComingSoonPage />} exact />
      </Routes>
    </BrowserRouter>
  );
}
