import { NavbarComponent } from "src/components/navbar";
import language from "src/language/index.json";
import { useContext } from "react";
import { LanguageContext } from "src/context/LanguageContext";
import { FooterComponent } from "src/components/footer";
import { CallToActionDefaultComponent } from "src/components/call-to-action";
import { AboutMainComponent } from "./component/main";
import { AboutWhoComponent } from "./component/who";
import { AboutWhyComponent } from "./component/why";
import { AboutMapComponent } from "./component/map";
import { AboutMotifComponent } from "./component/motif";

export default function AboutPage() {
  const { lang } = useContext(LanguageContext);

  return (
    <div className="overflow-hidden">
      <NavbarComponent />
      <AboutMainComponent lang={lang} language={language} />
      <AboutWhoComponent lang={lang} language={language} />
      <AboutMotifComponent />
      <AboutWhyComponent lang={lang} language={language} />
      <AboutMotifComponent />
      <AboutMapComponent lang={lang} language={language} />
      <CallToActionDefaultComponent lang={lang} language={language} />
      <FooterComponent lang={lang} language={language} />
    </div>
  );
}
