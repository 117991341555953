import iconAdministration from "src/assets/icons/product-expand-administration.png";
import iconCashier from "src/assets/icons/product-expand-cashier.png";
import iconReceptionist from "src/assets/icons/product-expand-receptionist.png";
import iconHelp from "src/assets/icons/product-expand-help.png";
import iconTicket from "src/assets/icons/product-expand-ticket.png";
import iconCustomer from "src/assets/icons/product-expand-customer.png";

import imageBG2 from "src/assets/images/home-bg-2.png";

export function ProductExpandComponent({ lang, language }) {
  const items = language[lang].product.expand.items;
  const icons = [
    iconAdministration,
    iconCashier,
    iconReceptionist,
    iconHelp,
    iconTicket,
    iconCustomer,
  ];

  return (
    <div className="w-full flex justify-center relative overflow-hidden">
      <img src={imageBG2} className="absolute w-full h-full object-cover" />
      <div className="2xl:w-[1500px] lg:w-9/12 w-11/12 py-20 relative">
        <div>
          <h6 className="text-sm" data-aos="fade-right" data-aos-duration="200">
            {language[lang].product.expand.label}
          </h6>
          <h2
            className="text-3xl font-semibold lg:w-96 mt-4"
            data-aos="fade-right"
            data-aos-duration="400"
          >
            {language[lang].product.expand.title}
          </h2>
        </div>
        <div className="flex flex-wrap gap-4 mt-12 2xl:w-[1200px] mx-auto justify-center">
          {items.map((item, index) => {
            return (
              <div
                key={index}
                className={`2xl:w-[380px] 2xl:h-[380px] w-[250px] h-[250px] flex flex-col justify-between border-4 p-5 rounded-xl ${
                  index % 2 === 0 ? "border-primary" : "border-third"
                }`}
                data-aos="fade-up"
                data-aos-duration={(index + 1) * 200}
              >
                <img
                  src={icons[index]}
                  alt="benefit"
                  className="w-20 h-20 object-cover ml-auto"
                />
                <h4 className="2xl:text-[24px] text-sm mt-8">{item}</h4>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
