import imageWhy from "src/assets/images/about-why.png";

export function AboutWhyComponent({ lang, language }) {
  const items = language[lang].about.why.items;

  return (
    <div className="w-full flex justify-center">
      <div className="max-w-[1366px] w-11/12 grid grid-cols-12 lg:gap-8 py-20">
        <div className="lg:col-span-3 col-span-12 bg-primary p-8 text-dark">
          <h2
            className="font-bold text-[48px]"
            data-aos="fade-right"
            data-aos-duration="400"
          >
            {language[lang].about.why.title}
          </h2>
          <div className="h-0.5 w-20 bg-dark my-8"></div>
          <p className="mt-4">{language[lang].about.why.description}</p>
        </div>
        <div className="lg:col-span-8 col-span-12 relative lg:mt-0 mt-8">
          <img
            src={imageWhy}
            className="absolute left-0 h-full object-cover lg:block hidden"
          />
          {items.map((item, index) => {
            return (
              <div className="flex lg:flex-row flex-col justify-between relative py-10">
                <div className="lg:w-[300px] lg:text-right text-left">
                  <h3
                    className="2xl:text-[48px] 2xl:leading-[48px] lg:text-2xl text-xl font-bold lg:font-normal lg:text-right text-left w-full"
                    data-aos="fade-right"
                    data-aos-duration="400"
                  >
                    {item.title}
                  </h3>
                </div>
                <div className="lg:w-[500px] lg:mt-0 text-justify 2xl:text-[20px]">
                  <p>{item.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
