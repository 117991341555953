import { CartProvider } from "./CartContext";

import { CombineComponents } from "./CombineComponents";
import { LoadingProvider } from "./LoadingContext";
import { SidebarProvider } from "./SidebarContext";
import { LanguageProvider } from "./LanguageContext";

const providers = [
  LoadingProvider,
  CartProvider,
  SidebarProvider,
  LanguageProvider,
];
export const AppContextProvider = CombineComponents(...providers);
