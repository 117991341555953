import { useNavigate } from "react-router-dom";
import imageLogo from "src/assets/images/home-about-logo.png";
import { ButtonDefaultComponent } from "src/components/button";

export function HomeAboutComponent({ lang, language }) {
  const navigate = useNavigate();

  return (
    <div className="flex w-full justify-center">
      <div className="2xl:w-[1500px] lg:w-9/12 w-11/12 grid grid-cols-12 lg:py-48 py-20 lg:gap-10 gap-6">
        <div className="lg:col-span-3 col-span-12">
          <img src={imageLogo} alt="logo" className="w-48 lg:w-full" />
        </div>
        <div className="lg:col-span-4 col-span-12 flex flex-col justify-center">
          <h6
            className="text-primary uppercase "
            data-aos="fade-up"
            data-aos-duration="200"
          >
            {language[lang].home.about.label}
          </h6>
          <h1
            className="font-bold 2xl:text-[48px] text-[36px] uppercase mt-2"
            data-aos="fade-up"
            data-aos-duration="400"
          >
            {language[lang].home.about.title}
          </h1>
        </div>
        <div className="lg:col-span-5 col-span-12 flex flex-col justify-center">
          <p
            className="text-justify text-[18px] font-light]"
            data-aos="fade-left"
            data-aos-duration="400"
          >
            {language[lang].home.about.description}
          </p>

          <div className="mt-6">
            <ButtonDefaultComponent
              title={language[lang].home.about.button}
              onClick={() => {
                navigate("/about");
                window.scrollTo(0, 0);
              }}
              dark={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
