import { FiArrowRight } from "react-icons/fi";
import { ButtonDefaultComponent } from "../button";
import imageCTA from "src/assets/images/call-to-action-bg.jpg";
import { useNavigate } from "react-router-dom";

export function CallToActionDefaultComponent({ lang, language }) {
  const navigate = useNavigate();
  return (
    <div className="w-full flex justify-center bg-slate-900 text-white relative">
      <img src={imageCTA} className="absolute w-full h-full object-cover" />

      <div className="2xl:w-[1300px] lg:w-9/12 w-11/12 flex flex-wrap justify-center py-20 relative">
        <div className="lg:w-1/2 w-full flex flex-col items-center lg:items-start">
          <h2
            className="text-[48px] font-bold w-11/12 lg:text-left text-center"
            data-aos="fade-right"
            data-aos-duration="400"
          >
            {language[lang].component["call-to-action"].title}
          </h2>
        </div>
        <div className="lg:w-1/2 w-full flex flex-col items-center lg:items-start lg:mt-0 mt-4">
          <p
            className="w-11/12 text-[24px] text-center lg:text-left"
            data-aos="fade-left"
            data-aos-duration="400"
          >
            {language[lang].component["call-to-action"].description}
          </p>
          <div className="mt-8">
            <button
              onClick={() => {
                navigate("/contact/information");
                window.scrollTo(0, 0);
              }}
              className="bg-primary rounded-lg py-4 px-4 text-dark font-bold text-[18px] flex gap-2 items-center"
            >
              {language[lang].component["call-to-action"].button}{" "}
              <FiArrowRight color="fff" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
